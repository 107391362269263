import { useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useRecoilState } from 'recoil';
import LoadingIcon from '~/assets/svgs/status.svg';
import TrashIcon from '~/assets/svgs/trash.svg';
import ProductName from '~/components/Items/ProductItem/ProductName';
import ProductPrice from '~/components/Items/ProductItem/ProductPrice';
import Text from '~/components/Text';
import Colors from '~/constants/colors';
import { PATH } from '~/constants/enum';
import { useCart } from '~/data/cart';
import { CartAtom, CartItem } from '~/services/cart';
import { trackingRemoveItemFromCart } from '~/services/tracking';
import { getUrlCustom } from '~/utils/theme';

type QuickCartMiniItemProps = {
    cartItem: CartItem;
    loading?: boolean;
    idDeleting?: string;
    setIdDeleting?: (id?: string) => void;
};

const QuickCartMiniItem = ({ cartItem, loading, idDeleting, setIdDeleting }: QuickCartMiniItemProps) => {
    const { removeCartItem, loadingRemoveCartItem } = useCart();
    const [cart] = useRecoilState(CartAtom.cart);

    const handleDelete = async (cartItemId: string) => {
        if (idDeleting) return;
        setIdDeleting?.(cartItemId);
        trackingRemoveItemFromCart(cartItem);
        await removeCartItem({
            variables: {
                cartId: cart.cartId,
                cartItemId
            }
        });
        setIdDeleting?.(undefined);
    };

    return (
        <div className="flex gap-4 relative">
            <div className="flex-shrink-0 w-1/5 aspect-square">
                {loading ? (
                    <Skeleton containerClassName="w-full" className="h-full" />
                ) : (
                    <img
                        className="object-cover"
                        src={cartItem?.product.image?.url || '/images/example_product.png'}
                        alt={cartItem?.product.name || ''}
                    />
                )}
            </div>
            <div className="flex-1 mr-1">
                <a
                    href={getUrlCustom({
                        pathname: PATH.ProductDetail,
                        query: {
                            variant: cartItem?.selectedVariant?.product?.id,
                            slug: cartItem?.product?.slug
                        }
                    })}
                >
                    <ProductName
                        name={cartItem?.selectedVariant?.product?.name || cartItem?.product?.name}
                        lineClamp={2}
                        loading={loading}
                    />
                </a>
                <div>
                    {loading ? (
                        <Skeleton />
                    ) : (
                        !!cartItem?.selectedVariant?.product?.price_range?.minimum_price?.final_price && (
                            <div className="flex gap-1 items-center">
                                <ProductPrice data={cartItem?.selectedVariant?.product as any} loading={false} />x{' '}
                                {cartItem?.quantity}
                            </div>
                        )
                    )}
                </div>
            </div>
            <div className="absolute top-0 -right-5 w-[20px] h-[20px]">
                {!loading ? (
                    loadingRemoveCartItem ? (
                        <span className="inline-flex">
                            <LoadingIcon
                                alt="Star"
                                color="#000"
                                className="animate-spin w-full h-full !text-[#000000]"
                            />
                        </span>
                    ) : (
                        <TrashIcon
                            color={Colors.GREY_300}
                            className="cursor-pointer !text-black w-full h-full"
                            onClick={() => handleDelete(cartItem.id)}
                        />
                    )
                ) : (
                    <Skeleton className="w-full h-full" />
                )}
            </div>
        </div>
    );
};

export default QuickCartMiniItem;
