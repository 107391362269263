import React from 'react';
import CloseIcon from '~/assets/svgs/close.svg';
import Container from '../Container';
import Colors from '~/constants/colors';
import classNames from 'classnames';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { AlertAtom } from '~/services/alerts';
import { getContrastingTextColor } from '~/utils/helper';

type Props = {};

const Alert = (props: Props) => {
    const { message, open, type } = useRecoilValue(AlertAtom.alerts);
    const setAlert = useSetRecoilState(AlertAtom.alerts);

    const handleClose = () => {
        setAlert(() => ({
            message: '',
            open: false,
            type: 'info'
        }));
    };

    return (
        <div
            className={classNames('relative max-h-0 overflow-hidden transition-all ease-in-out duration-200 ', {
                'max-h-40': !!open && !!message
            })}
            style={{
                backgroundColor: '#fae6cb'
            }}
        >
            <Container>
                <div className=" ">
                    <div
                        className="content py-2 text-center px-2"
                        dangerouslySetInnerHTML={{
                            __html: message
                        }}
                    />
                </div>
            </Container>
            <span
                className="absolute right-0 lg:right-4 top-0 px-0 py-2  lg:p-2 rounded-sm hover:rounded-full hover:bg-[#fff4e6] hover:cursor-pointer"
                onClick={handleClose}
            >
                <CloseIcon className="!text-black" width={24} height={24} color="#000" />
            </span>
        </div>
    );
};

export default Alert;
