import { PaymentRequestButtonElement, useStripe } from '@stripe/react-stripe-js';
import { StripePaymentRequestButtonElementOptions } from '@stripe/stripe-js';
import classNames from 'classnames';
import { get } from 'lodash';
import router from 'next/router';
import { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { PATH } from '~/constants/enum';
import cartAtom from '~/services/cart/cart.atom';
import OrderAtom from '~/services/order/order.atom';
import { PaymentMethods } from '~/services/payment';
import { useOptions, usePaymentRequest } from '../hooks';
import styles from '../PaymentStripe.module.scss';
import {
    applyShipping,
    estimateShippingCart,
    getApplePayParams,
    getClientSecretFromResponse,
    placeOrder
} from '../services';
import { getShippingAddressFrom } from './utils';
import { useCart } from '~/data/cart';
import { transFormCart } from '~/utils/transform';

export type PaymentRequestFormProps = {
    onError?: (error: { message: string }) => void;
    onReady?: () => void;
    text?: string;
    loading?: boolean;
    onClick?: (event: any) => void;
};

const PaymentRequestForm = ({ onClick, loading, onError, onReady, ...props }: PaymentRequestFormProps) => {
    const stripe: any = useStripe();
    const cart = useRecoilValue(cartAtom.cartSelector);

    const { cartId } = cart;
    const setCart = useSetRecoilState(cartAtom.cart);
    const setOrder = useSetRecoilState(OrderAtom.order);

    const [isReady, setIsReady] = useState<any>(false);
    const [paymentRequest, setPaymentRequest] = useState<any>(null);
    const [canMakePayment, setCanMakePayment] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [error, setError] = useState<any>(null);

    const { getCartInfo } = useCart();

    let shippingAddress: any;
    let shippingMethod: any;

    const onShippingAddressChange = async (event: any) => {
        shippingAddress = getShippingAddressFrom(event.shippingAddress);

        try {
            const response = await estimateShippingCart({
                address: shippingAddress,
                cartId
            });
            const shippingOptions = JSON.parse(response).results;

            if (shippingOptions.length < 1) {
                event.updateWith({ status: 'invalid_shipping_address' });
                return;
            }
            let _shippingMethod = null;
            if (shippingOptions.length > 0) {
                const shippingOption = shippingOptions[0];
                _shippingMethod = shippingOption.hasOwnProperty('id') ? shippingOption.id : null;
            }

            const applyShippingResponse = await applyShipping({
                address: shippingAddress,
                shippingId: _shippingMethod,
                cartId
            });

            shippingMethod = _shippingMethod;
            const result = {
                status: 'success',
                shippingOptions: shippingOptions,
                ...JSON.parse(applyShippingResponse)?.results
            };
            event.updateWith(result);
        } catch (error) {
            console.error('Error:', error);
            event.updateWith({ status: 'fail' });
        }
    };
    const onShippingOptionChange = async (event: any) => {
        try {
            const _shippingMethod = event.shippingOption?.id || null;
            if (!shippingAddress) {
                event.updateWith({ status: 'invalid_shipping_address' });
                return;
            }

            const applyShippingResponse = await applyShipping({
                address: shippingAddress,
                shippingId: _shippingMethod,
                cartId
            });
            shippingMethod = event.shippingOption?.id || null;
            const result = JSON.parse(applyShippingResponse);

            // Ensure that a 'status' is provided in the response
            if (result && result.results) {
                event.updateWith({ status: 'success', ...result.results });
            } else {
                event.updateWith({ status: 'fail' });
            }
        } catch (error) {
            console.error('Error:', error);
            event.updateWith({ status: 'fail' });
        }
    };
    const onPaymentMethod = async (result: any) => {
        const payload: any = {
            result: result,
            location: 'minicart'
        };
        setDisabled(true);
        const getCartsInfo = await getCartInfo({
            variables: {
                cartId
            }
        });
        const cart = transFormCart(get(getCartsInfo, 'data', { cart: {} }));
        try {
            const response = await placeOrder({
                cartId,
                payload
            });

            let data = response.data;

            // Nếu response là chuỗi, cố gắng parse thành JSON
            if (typeof data === 'string') {
                try {
                    data = JSON.parse(data);
                } catch (e) {
                    throw new Error('Invalid JSON response: ' + data); // Đảm bảo lỗi có cấu trúc
                }
            }
            setDisabled(false);

            // redirect to success page or handle success
            // closePaysheet
            result.complete('success');
            handleOnSuccess({ ...data[0], ...result }, cart);
        } catch (error: any) {
            setDisabled(false);
            // Kiểm tra lỗi từ server response
            if (error.response) {
                const response = error.response.data;

                try {
                    // Lấy clientSecret từ response.message
                    const clientSecret = getClientSecretFromResponse(response.message);
                    if (clientSecret) {
                        // Xác thực Stripe
                        const { error } = await stripe.confirmCardPayment(clientSecret);
                        // Nếu thành công, gọi lại hàm placeOrder để đặt hàng lại
                        return await onPaymentMethod(result);
                    } else {
                        throw new Error(error);
                    }
                } catch (e) {
                    // Hiển thị hoặc log lỗi với cấu trúc rõ ràng
                    console.error('Payment processing error:', error.response.data);

                    // throw new Error("Payment processing error: " + error.response.data);
                    toast.error('Payment processing error: ' + get(error, 'response.data.message', ''));
                }
            } else {
                // Xử lý các lỗi khác (network, etc.)
                console.error('Network error:', error.message);
                throw new Error('Network error: ' + error.message);
            }
        }
    };

    const handleOnSuccess = (res: any, cart?: any) => {
        toast.success('Congratulation, your order is completed');
        setOrder({
            ...cart,
            email: res.payerEmail,
            first_name: res.payerName,
            telephone: res.payerPhone,
            order_number: res.order_number,
            order_id: res.order_id,
            shipping_addresses: [
                {
                    // ...cart?.shipping_addresses?.[0],
                    country: {
                        code: res.shippingAddress?.country,
                        label: ''
                    },
                    city: res.shippingAddress?.city,
                    street: res.shippingAddress?.addressLine?.[0],
                    country_code: res.shippingAddress?.country,
                    telephone: res.shippingAddress?.phone,
                    postcode: res.shippingAddress?.postalCode,
                    region: {
                        code: res.shippingAddress?.region,
                        label: ''
                    },
                    region_code: res.shippingAddress?.region,
                    firstname: res.shipping_addresses?.recipient,
                    lastname: ''
                }
            ],
            billing_address: {
                // ...cart?.shipping_addresses?.[0],
                country: {
                    code: res.shippingAddress?.country,
                    label: ''
                },
                city: res.shippingAddress?.city,
                street: res.shippingAddress?.addressLine?.[0],
                country_code: res.shippingAddress?.country,
                telephone: res.shippingAddress?.phone,
                postcode: res.shippingAddress?.postalCode,
                region: {
                    code: res.shippingAddress?.region,
                    label: ''
                },
                region_code: res.shippingAddress?.region,
                firstname: res.shipping_addresses?.recipient,
                lastname: ''
            },
            selected_payment_method: {
                title: `Pay by Card (${res.walletName})`,
                code: PaymentMethods.STRIPE
            },
            selected_shipping_method: {
                amount: res.shippingOption?.amount,
                carrier_code: res.shippingOption?.id,
                carrier_title: res.shippingOption?.label,
                method_code: res.shippingOption?.method_code,
                method_title: res.shippingOption?.method_title
            },
            shipping_fee: (res.shippingOption?.amount || 0) / 100,
            // new by stripe
            paymentMethod: res.payment_method,
            referer: PATH.Checkout
        });
        setCart((prevCart) => ({
            ...prevCart,
            statusPlaceOrder: 'success',
            selected_payment_method: {
                title: 'Pay by Card',
                code: PaymentMethods.STRIPE
            }
        }));
        router.push('/thank-you');
    };

    const getOptions = async (cartId: string) => {
        try {
            const params = await getApplePayParams({ cartId });
            return JSON.parse(params);
        } catch (error) {
            setError(error);
            onError?.({
                message: 'Error fetching Apple Pay params'
            });
            console.error('Error fetching Apple Pay params:', error);
            return null;
        }
    };

    useEffect(() => {
        if (!cartId) return;
        if (!paymentRequest) return;
        if (!canMakePayment) return;
        if (!!cart.loading) return;
        if (!cart.total) return;
        if (!!disabled) return;
        if (!cartId) return;
        if (cart.statusPlaceOrder === 'success') return;
        // paymentRequest.update({
        //   total: {
        //     label: "Total",
        //     amount: cart.total * 100,
        //   },
        // });
        setDisabled(true);
        getOptions(cartId)
            .then((res: any) => {
                if (!res) return;
                const result = {
                    displayItems: res.displayItems,
                    total: res.total
                };
                paymentRequest.update(result);
                // const pr: any = stripe.paymentRequest(res);
                // setPaymentRequest(pr);
            })
            .finally(() => {
                setDisabled(false);
            });
    }, [cart.total, cart.loading, cartId]);

    const init = useRef(false);
    useEffect(() => {
        if (!!init.current) return;
        if (!!paymentRequest) return;
        if (!stripe) return;
        if (!cartId) return;

        init.current = true;
        getOptions(cartId).then((res: any) => {
            if (!res) return;
            const pr: any = stripe.paymentRequest(res);
            setPaymentRequest(pr);
        });
    }, [stripe, cartId]);

    useEffect(() => {
        let subscribed = true;
        if (paymentRequest) {
            paymentRequest.canMakePayment().then((res: any) => {
                if (res && subscribed) {
                    setCanMakePayment(true);
                }
            });
        }

        return () => {
            subscribed = false;
        };
    }, [paymentRequest]);

    useEffect(() => {
        if (paymentRequest) {
            paymentRequest.on('paymentmethod', onPaymentMethod);
            paymentRequest.on('shippingaddresschange', onShippingAddressChange);
            paymentRequest.on('shippingoptionchange', onShippingOptionChange);
        }
        return () => {
            if (paymentRequest) {
                paymentRequest.off('paymentmethod', onPaymentMethod);
                paymentRequest.on('shippingaddresschange', onShippingAddressChange);
                paymentRequest.on('shippingoptionchange', onShippingOptionChange);
            }
        };
    }, [paymentRequest]);

    const options: StripePaymentRequestButtonElementOptions = useOptions(paymentRequest);

    if (!!error) return;
    if (!paymentRequest || !canMakePayment) {
        return <div className="w-full h-[40px] flex items-center justify-center ">...loading</div>;
    }

    return (
        <>
            <div className="relative">
                {!isReady && <div className="w-full  h-[40px] flex items-center justify-center ">...loading</div>}
                <PaymentRequestButtonElement
                    {...props}
                    onClick={(e: any) => {
                        onClick?.(e);
                        if (onClick) {
                            e.preventDefault();
                        }
                    }}
                    className={classNames({
                        [styles.loadingOverlay]: loading || disabled || !cart?.items?.length
                    })}
                    options={options}
                    onReady={() => {
                        setIsReady(true);
                        onReady?.();
                    }}
                />
            </div>
        </>
    );
};

export default PaymentRequestForm;
