import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';

import Skeleton from 'react-loading-skeleton';
import { useRecoilValue } from 'recoil';
import cartAtom from '~/services/cart/cart.atom';
import storeConfigAtom from '~/services/store-config/store-config.atom';
import ExpressPaymentForm from './FormStripes/ExpressPayment';
import PaymentRequestForm, { PaymentRequestFormProps } from './FormStripes/PaymentRequestForm';

type Props = {
    type?: 'PaymentRequest' | 'CardElement' | 'ExpressPayment';
    [x: string]: any;
} & PaymentRequestFormProps;

const ComponentsElements = {
    PaymentRequest: (props: PaymentRequestFormProps) => <PaymentRequestForm {...props} />,
    // CardElement: CardElement,
    ExpressPayment: (props: Props) => <ExpressPaymentForm {...props} />
};

const PaymentStripe = ({ type = 'PaymentRequest', ...otherProps }: Props) => {
    const { stripe_mode, stripe_live_pk, stripe_test_pk } = useRecoilValue(storeConfigAtom.store);
    const { cartId } = useRecoilValue(cartAtom.cartSelector);
    const [stripePromise, setStripePromise] = useState<any>();
    const [options, setOptions] = useState<any>(null);
    const [typeState, setTypeState] = useState<any>(type);
    //   const

    //   const stripePromise = loadStripe("pk_test_6pRNASCoBOKtIshFeQd4XMUh");
    useEffect(() => {
        if (process.env.NEXT_PUBLIC_ENABLE_PAYPAL_STRIPE === '0') {
            otherProps?.onError?.({
                message: 'Payment method is not available'
            });
        }
        if (stripe_mode === 'test' && stripe_test_pk) {
            setStripePromise(loadStripe(stripe_test_pk));
        } else if (stripe_mode === 'live' && stripe_live_pk) {
            setStripePromise(loadStripe(stripe_live_pk));
        }
    }, [stripe_mode, stripe_live_pk, stripe_test_pk]);

    if (process.env.NEXT_PUBLIC_ENABLE_PAYPAL_STRIPE === '0') return;

    if (!stripePromise)
        return (
            <div className="flex h-[40px] items-center justify-center">
                <Skeleton height={40} width={'100%'} />
            </div>
        );

    return (
        <div className="relative">
            <Elements stripe={stripePromise}>{<PaymentRequestForm {...otherProps} />}</Elements>
            {/* <div
        className="text-center hover:underline mt-2 hover:cursor-pointer"
        onClick={() => setTypeState("ExpressPayment")}
      >
        Switch to paymnet express
      </div> */}
        </div>
    );
};

export default PaymentStripe;
